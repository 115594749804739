$primary: rgba(255, 0, 0, 1);
$primary_transparency: rgba(255, 0, 0, 0.7);
@import "./bootstrap/bootstrap";

body {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  background-color: #ccc;
  // Pas besoin de charger l'image d'arrière plan en mobile, elle ne sera pas affichée
  @include media-breakpoint-up(sm) {
    background-image: url("/assets/img/mip-bg-white.gif");
    background-position: left top;
    background-size: auto;
    background-repeat: repeat;
    background-attachment: scroll;
  }
}

.content-container {
  background-color: #f8f8f8;
  overflow: auto;
}

.main-container {
  padding: 0;
  box-shadow: 0 0 18px rgba(0, 0, 0, .2)
}

.card {
  margin: 32px;
}

blockquote {
  position: relative;
  color: #444;
  font-family: serif;
  font-style: italic;
  margin: 0 30px 0 60px;
  quotes: none;
  &:before {
    line-height: 0;
    position: absolute;
    top: 30px;
    left: -75px;
    content: "\201C";
    color: #DDD;
    font-size: 96px;
  }
}

.bg-dark {
  background-color: #111 !important;
}

.category-title {
  font-size: 32px;
  font-style: italic;
  font-weight: 300;

  padding: 20px 36px;
  background-color: #eeeeee;
  border-bottom: 1px solid #e1e1e1;
}
