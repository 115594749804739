/* oswald-300 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/oswald-v31-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/oswald-v31-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/oswald-v31-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/oswald-v31-latin-300.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/oswald-v31-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/oswald-v31-latin-300.svg#Oswald') format('svg'); /* Legacy iOS */
}

/* oswald-regular - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/oswald-v31-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/oswald-v31-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/oswald-v31-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/oswald-v31-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/oswald-v31-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/oswald-v31-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/open-sans-v17-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('/assets/fonts/open-sans-v17-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/open-sans-v17-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/open-sans-v17-latin-300.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/open-sans-v17-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/open-sans-v17-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/open-sans-v17-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url('/assets/fonts/open-sans-v17-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/open-sans-v17-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/open-sans-v17-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/open-sans-v17-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/open-sans-v17-latin-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('/assets/fonts/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/open-sans-v17-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('/assets/fonts/open-sans-v17-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/open-sans-v17-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/open-sans-v17-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/open-sans-v17-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/open-sans-v17-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/open-sans-v17-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('/assets/fonts/open-sans-v17-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/open-sans-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/open-sans-v17-latin-600.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/open-sans-v17-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/open-sans-v17-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/open-sans-v17-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
  url('/assets/fonts/open-sans-v17-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/open-sans-v17-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/open-sans-v17-latin-600italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/open-sans-v17-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/open-sans-v17-latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}



