@import "bootstrap/variables";

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.nav-link {
  color: $white !important;
  padding: 1rem 1.5rem !important;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
}

.active, .nav-link:hover {
  background-color: $primary_transparency;
}

.dropdown-menu {
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.dropdown-divider {
  margin: 0;
}

.dropdown-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
  &:hover {
    background-color: $primary_transparency;
    color: white;
  }
}
